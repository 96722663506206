// Fonts
//@import url('https://fonts.googleapis.com/css?family=Raleway');
/* raleway-regular - latin-ext_latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/raleway-v17-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/raleway-v17-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/raleway-v17-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/raleway-v17-latin-ext_latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin-ext_latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/raleway-v17-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/raleway-v17-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/raleway-v17-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/raleway-v17-latin-ext_latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-900 - latin-ext_latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/raleway-v17-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/raleway-v17-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/raleway-v17-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/raleway-v17-latin-ext_latin-900.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin-ext_latin */
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/raleway-v17-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/raleway-v17-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/raleway-v17-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/raleway-v17-latin-ext_latin-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700italic - latin-ext_latin */
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/raleway-v17-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/raleway-v17-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/raleway-v17-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/raleway-v17-latin-ext_latin-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-900italic - latin-ext_latin */
@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 900;
    src: url('/fonts/raleway-v17-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/fonts/raleway-v17-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/raleway-v17-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('/fonts/raleway-v17-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/raleway-v17-latin-ext_latin-900italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

html,
body {
    background-color: #ffffff;
    font-smooth: antialiased;
}
h3 {
    font-size: 1.2rem;
    font-weight: bold;
}
.pb-6 {
    padding-bottom: 3rem;
}
.pb-7 {
    padding-bottom: 4rem;
}
.pb-8 {
    padding-bottom: 5rem;
}
.pb-9 {
    padding-bottom: 6rem;
}


.mh-60 {
    max-height: 60px;
}



html,
body,
#app {
    min-height: 100vh;
}
#app.welcome main {
    position: relative;
    background-image: url(/img/welcome-carte-menu.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: calc(100vh - 62px - 52px);
}
#app.welcome main::after {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(39,45,55,0) 0%, rgba(39,45,55,0.25) 50%, rgba(39,45,55,0.75) 100%);
}
#app.welcome p.lead {
    font-size: 1.5rem;
    color: #ffffff;
    text-shadow: 0 0 0.5rem #000000;
}
#app.welcome main .welcome {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
}
#app.welcome main .welcome .container {
    position: relative;
    z-index: 2;
}
footer {
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $secondary;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #ffffff;
    a {
        color: #ffffff;
    }
    a:hover {
        color: $blue;
        text-decoration: none;
    }
    p {
        margin-bottom: 0;
    }
}
#app.register footer {
    position: static;
}


nav {
    position: relative;
    z-index: 1;
    .logo {
        width: 120px;
    }
}
nav.navbar {
    z-index: 2;
}
nav.navbar-light .navbar-nav .nav-link.btn {
    color: #ffffff;
}
nav.navbar-expand .navbar-nav .dropdown-menu {
    z-index: 1000;
}
.navbar-light .navbar-nav .nav-link {
    color: $bleu;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: $noir;
}
.btn-link {
    color: $noir;
}
nav.interieure a {
    box-shadow: 0 0 10px rgba($noir, 0.25);
    padding: 0.25rem;
    margin-right: 0.5rem;
    color: $noir;
    border-radius: 0.25rem;
    line-height: 235%;
    white-space: nowrap;
    transition: $timing;
    font-size: 0.8rem;
}
nav.interieure a:hover,
nav.interieure a.active {
    text-decoration: none;
    color: $bleu;
}

.btn.rounded-pill {
    margin-bottom: 0.5rem;
}
.btn-group.rounded-pill {
    .btn {
        padding: 0.1875rem 1rem;
    }
    .btn:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
    .btn:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
}



.categories .categorie:not(.is-selected) .produits {
    opacity: 0;
}
.categories {
    .categorie {
        width: 80vw;
        margin: 0 auto 2rem;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: stretch;
        align-items: stretch;
        align-content: center;
        padding-top: 0.75rem;
        .nom {
            height: 100px;
            background: $noir;
            color: white;
            border-radius: 1rem;
            text-shadow: 0 0 0.3rem black;
            text-align: center;
            padding-top: 3rem;
            padding-bottom: 3rem;
            box-shadow: 0 0 10px $noir;
            font-size: 1.5rem;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            background-size: cover;
            background-position: center center;
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }

    .produits {
        margin-top: 2rem;
        transition: $timing;
        margin-right: -8vw;
        margin-left: -8vw;
        .produit {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            align-content: stretch;
            .texte {
                position: relative;
                z-index: 1;
            }
            .photo {
                width: 25%;
                .regPop {
                    display: inline-block;
                }
            }
            .titreprix h3 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: stretch;
                align-content: stretch;
            }
            .description {
                font-size: 0.75rem;
            }
        }
    }
}


.flickity-prev-next-button {
    top: 60px;
}
.produitsEnUne .flickity-prev-next-button {
    top: 50%;
}
.flickity-button:disabled {
    opacity: 0;
}
.carousel-cell {
    width: 75%;
}


.rounded.img-fluid {
    border-radius: 0.5rem !important;
}




/* A masquer en prod */
ins.adsbygoogle {
    background-color: rgba($bleu, 0.1);
    position: relative;
}
ins.adsbygoogle::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "ANNONCEUR";
    color: $blue;
    font-weight: bold;
    font-size: 2rem;
    text-shadow: 0 0 10px $blue;
}
/* */


.form-group .input-group-prepend .input-group-text img[src$="svg"],
.btn.btn-primary svg,
.paginate svg {
    width: 1.2rem;
}

label {
    cursor: pointer;
}
.col-form-label,
.col-form-label-checkbox {
    color: rgba($noir, 0.75);
}
textarea.form-control {
    min-height: 10rem;
}
.support-technique textarea.form-control {
    min-height: 18rem;
}
.form-control:focus,
.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba($bleu, 0.25);
    border-color: $bleu;
}
.custom-file-label {
    white-space: nowrap;
    overflow: hidden;
}
.custom-file-label::after {
    content: "Sélection";
}
::placeholder,
.form-control::placeholder {
    color: rgba($noir, 0.35);
}
.form-control,
input[type=radio],
input[type=checkbox] {
    box-shadow: 0 0 10px #ccc;
}
.form-control {
    border-radius: 0.5rem;
    border-color: rgba($noir, 0.25);
}
.custom-file-label {
    border-radius: 0.5rem;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}



.sortable-placeholder {
    list-style-type: none;
    border: 1px solid $bleu;
    box-shadow: 0 0 0 0.2rem rgba($bleu, 0.25);
}
.list-group-item {
    padding: 0.375rem 0.625rem;
}



div.col-xs-11[data-notify="container"] {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
}


div#regPopUp {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
    align-content: center;
    background: rgba(0,0,0,0.75);
    z-index: 1000;
    padding: 1rem;
    opacity: 1;
    transition: 250ms;
    cursor: pointer;
}
div#regPopUp.fermer {
    display: none;
    opacity: 0;
}
#regPopUp img.regPopUp {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0.75rem;
}
div#regPopUp::after {
    content: "";
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    background-image: url(/img/fermer.svg);
    background-repeat: no-repeat;
    background-position: top right;
}


.cacher {
    opacity: 0.6;
}
.produits.range {
    height: 0;
    overflow: hidden;
}


#app.restaurateur {
    nav .conteneur {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    #espacemembre {
        position: absolute;
        right: 0;
    }
    .reseauxsociaux {
        text-align: center;
    }
    .reseauxsociaux .rs {
        display: inline-block;
        width: 1.5rem;
        color: $noir;
        margin-right: 0.25rem;
        margin-left: 0.25rem;
        transition: $timing;
        svg {
            height: 1.5rem;
        }
    }
    .reseauxsociaux .rs:hover {
        color: $blue;
    }
    footer {
        position: relative;
    }
}


.reseauxsociaux-index main img[src$="svg"],
.svg-fa-icon {
    max-height: 1.5rem;
    max-width: 1.5rem;
}


.cupo {
    cursor: pointer;
}


// Stripe
#card-element > div {
    width: 100%;
}


/* ****************************** */
/*
img.qrcode {
    width: 100%;
}
.categorietitre {
    cursor: pointer;
}
*/